<template>
  <div>
    <div class="home">
    </div>
  </div>
</template>

<script>

export default {
  name: "home",
};
</script>

<style lang="postcss" scoped>
  .home {
    background: url('./../assets/home-bg.jpg') no-repeat top center;
    background-size: cover;
    z-index: -1;
    @apply absolute top-0 bottom-0 left-0 right-0
  }
</style>
